import React, { createContext, useContext, useState, useEffect } from 'react';

const developers = [
    { name: 'Savvas', color: 'bg-red-500' },
    { name: 'Stavros', color: 'bg-blue-500' },
    { name: 'Sofia', color: 'bg-green-500' },
    { name: 'Georgia', color: 'bg-yellow-500' },
    { name: 'Oleh', color: 'bg-purple-500' },
    { name: 'Marios', color: 'bg-pink-500' },
];

const technologies = [
    'Laravel', 'Vue', 'React', 'DevOps', 'Docker', 'PM', 'UI/UX', 'Documentation',
    'Alpine JS', 'Python', 'AI', 'Architect Solution', 'Project Analysis'
];

const projects = {
    'Carespot': ['Laravel', 'Vue', 'DevOps', 'Docker', 'PM', 'UI/UX', 'Documentation'],
    'Stay Independent': ['Laravel', 'Vue', 'DevOps', 'Docker', 'PM', 'UI/UX', 'Documentation'],
    'ConnectLine': ['React', 'PM', 'UI/UX', 'Documentation'],
    'VashCenter': ['Vue', 'Laravel', 'Docker', 'PM', 'Documentation'],
    'Futurae': ['Alpine JS', 'Documentation'],
    'Crosswold': ['React', 'DevOps', 'UI/UX', 'PM', 'Documentation', 'Docker', 'Laravel', 'Project Analysis', 'Architect Solution'],
    'Sinastry': ['React', 'Python', 'UI/UX', 'PM', 'Documentation', 'Docker']
};

const products = {
    'Our Products': ['DevOps', 'Docker', 'Laravel', 'React', 'UI/UX', 'Documentation', 'AI', 'Architect Solution', 'Project Analysis', 'PM']
};

const AppContext = createContext();

const Badge = ({ children, className, skillLevel }) => {
    let opacity = skillLevel === 1 ? 'opacity-30' : 'opacity-100';
    return (
        <div className={`inline-flex items-center rounded-full px-2.5 py-0.5 text-xs font-medium ${className} ${opacity} transition-opacity duration-200`}>
            {children}
        </div>
    );
};

const AppProvider = ({ children }) => {
    const [assignments, setAssignments] = useState(() => {
        const savedAssignments = localStorage.getItem('assignments');
        return savedAssignments ? JSON.parse(savedAssignments) : {};
    });

    useEffect(() => {
        localStorage.setItem('assignments', JSON.stringify(assignments));
    }, [assignments]);

    const toggleAssignment = (projectOrProduct, tech, dev) => {
        setAssignments(prev => {
            const newAssignments = { ...prev };
            if (!newAssignments[projectOrProduct]) newAssignments[projectOrProduct] = {};
            if (!newAssignments[projectOrProduct][tech]) newAssignments[projectOrProduct][tech] = { hours: 0, developers: [] };

            const devIndex = newAssignments[projectOrProduct][tech].developers.findIndex(d => d.name === dev.name);
            if (devIndex !== -1) {
                if (newAssignments[projectOrProduct][tech].developers[devIndex].skillLevel === 2) {
                    newAssignments[projectOrProduct][tech].developers.splice(devIndex, 1);
                } else {
                    newAssignments[projectOrProduct][tech].developers[devIndex].skillLevel += 1;
                }
            } else {
                newAssignments[projectOrProduct][tech].developers.push({ ...dev, skillLevel: 1 });
            }
            return newAssignments;
        });
    };

    const setHours = (projectOrProduct, tech, hours) => {
        setAssignments(prev => {
            const newAssignments = { ...prev };
            if (!newAssignments[projectOrProduct]) newAssignments[projectOrProduct] = {};
            if (!newAssignments[projectOrProduct][tech]) newAssignments[projectOrProduct][tech] = { hours: 0, developers: [] };
            newAssignments[projectOrProduct][tech].hours = hours;
            return newAssignments;
        });
    };

    const resetAssignments = () => {
        setAssignments({});
        localStorage.removeItem('assignments');
    };

    return (
        <AppContext.Provider value={{ assignments, toggleAssignment, setHours, resetAssignments }}>
            {children}
        </AppContext.Provider>
    );
};

const ProjectsAndProductsColumn = ({ title, items }) => {
    const { assignments, toggleAssignment, setHours } = useContext(AppContext);

    return (
        <div className="overflow-y-auto h-screen pb-20">
            <h2 className="text-2xl font-bold mb-4 sticky top-0 bg-white z-10">{title}</h2>
            {Object.entries(items).map(([name, techs]) => (
                <div key={name} className="mb-6 p-4 border rounded shadow">
                    <h3 className="text-xl font-semibold mb-2">{name}</h3>
                    {techs.map(tech => (
                        <div key={tech} className="mb-4">
                            <div className="flex items-center mb-2">
                                <span className="font-medium mr-2">{tech}:</span>
                                <input
                                    type="number"
                                    min="0"
                                    value={assignments[name]?.[tech]?.hours || 0}
                                    onChange={(e) => setHours(name, tech, parseInt(e.target.value) || 0)}
                                    className="border rounded px-2 py-1 w-20 mr-2"
                                />
                                <span>hours</span>
                            </div>
                            <div className="flex flex-wrap">
                                {developers.map(dev => {
                                    const assignedDev = assignments[name]?.[tech]?.developers.find(d => d.name === dev.name);
                                    const skillLevel = assignedDev ? assignedDev.skillLevel : 0;
                                    return (
                                        <button
                                            key={dev.name}
                                            onClick={() => toggleAssignment(name, tech, dev)}
                                            className={`mr-2 mb-2 px-3 py-1 text-sm rounded-full transition-colors duration-200 ${
                                                skillLevel > 0
                                                    ? `${dev.color} text-white ${skillLevel === 1 ? 'opacity-30' : ''}`
                                                    : 'bg-gray-200 hover:bg-gray-300'
                                            }`}
                                        >
                                            {dev.name}
                                        </button>
                                    );
                                })}
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};

const TechnologySummaryColumn = () => {
    const { assignments } = useContext(AppContext);

    const summarizeTechnology = (tech) => {
        let totalHours = 0;
        const devSummary = {};

        Object.entries(assignments).forEach(([projectOrProduct, technologies]) => {
            if (technologies[tech]) {
                totalHours += technologies[tech].hours || 0;
                technologies[tech].developers.forEach(dev => {
                    if (!devSummary[dev.name]) {
                        devSummary[dev.name] = { skillLevel: dev.skillLevel, color: dev.color };
                    } else if (dev.skillLevel > devSummary[dev.name].skillLevel) {
                        devSummary[dev.name].skillLevel = dev.skillLevel;
                    }
                });
            }
        });

        return { totalHours, devSummary };
    };

    return (
        <div className="overflow-y-auto h-screen pb-20">
            <h2 className="text-2xl font-bold mb-4 sticky top-0 bg-white z-10">Technology Summary</h2>
            {technologies.map(tech => {
                const { totalHours, devSummary } = summarizeTechnology(tech);
                if (totalHours === 0 && Object.keys(devSummary).length === 0) return null;
                return (
                    <div key={tech} className="mb-4 p-4 border rounded shadow">
                        <h3 className="text-xl font-semibold mb-2">{tech}: {totalHours} hours</h3>
                        <div className="flex flex-wrap">
                            {Object.entries(devSummary).map(([devName, { skillLevel, color }]) => (
                                <Badge
                                    key={devName}
                                    className={`mr-2 mb-2 ${color} text-white`}
                                    skillLevel={skillLevel}
                                >
                                    {devName} ({skillLevel === 1 ? 'Basic' : 'Advanced'})
                                </Badge>
                            ))}
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const DeveloperSummaryColumn = () => {
    const { assignments } = useContext(AppContext);

    const summarizeDeveloper = (dev) => {
        let totalHours = 0;
        const techSummary = {};

        Object.entries(assignments).forEach(([projectOrProduct, technologies]) => {
            Object.entries(technologies).forEach(([tech, { hours, developers }]) => {
                if (developers.some(d => d.name === dev.name)) {
                    totalHours += hours;
                    if (!techSummary[tech]) techSummary[tech] = { totalHours: 0, projects: {} };
                    techSummary[tech].totalHours += hours;
                    if (!techSummary[tech].projects[projectOrProduct]) {
                        techSummary[tech].projects[projectOrProduct] = hours;
                    } else {
                        techSummary[tech].projects[projectOrProduct] += hours;
                    }
                }
            });
        });

        return { totalHours, techSummary };
    };

    return (
        <div className="overflow-y-auto h-screen pb-20">
            <h2 className="text-2xl font-bold mb-4 sticky top-0 bg-white z-10">Developer Summary</h2>
            {developers.map(dev => {
                const { totalHours, techSummary } = summarizeDeveloper(dev);
                if (totalHours === 0) return null;
                return (
                    <div key={dev.name} className="mb-6 p-4 border rounded shadow">
                        <h3 className="text-xl font-semibold mb-3">
                            {dev.name}: <span className="text-2xl">{totalHours} hours</span>
                        </h3>
                        {Object.entries(techSummary).map(([tech, { totalHours, projects }]) => (
                            <div key={tech} className="mb-4">
                                <div className="flex items-center mb-2">
                                    <span className="font-medium mr-2">{tech}:</span>
                                    <span className="bg-gray-200 px-2 py-1 rounded">{totalHours} hours</span>
                                </div>
                                <div className="pl-4">
                                    {Object.entries(projects).map(([project, hours]) => (
                                        <div key={project} className="text-sm text-gray-600">
                                            {project}: {hours} hours
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    </div>
                );
            })}
        </div>
    );
};

const ResetButton = () => {
    const { resetAssignments } = useContext(AppContext);
    return (
        <button
            onClick={resetAssignments}
            className="fixed bottom-4 right-4 px-4 py-2 bg-red-500 text-white hover:bg-red-600 rounded transition-colors duration-200"
        >
            Reset All Assignments
        </button>
    );
};

export default function ProjectProductTechnologyMatcher() {
    return (
        <AppProvider>
            <div className="flex p-4 h-screen">
                <div className="w-1/3 pr-4">
                    <ProjectsAndProductsColumn title="Projects" items={projects} />
                    <ProjectsAndProductsColumn title="Products" items={products} />
                </div>
                <div className="w-1/3 px-2">
                    <TechnologySummaryColumn />
                </div>
                <div className="w-1/3 pl-4">
                    <DeveloperSummaryColumn />
                </div>
                <ResetButton />
            </div>
        </AppProvider>
    );
}
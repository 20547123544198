import React from 'react';
import DeveloperTechnologyMatcher from './components/DeveloperTechnologyMatcher';

function App() {
  return (
      <div className="App">
        <DeveloperTechnologyMatcher />
      </div>
  );
}

export default App;